@font-face {
  font-family: "Michroma";
  src: url("./styles/fonts/Michroma-Regular.ttf") format("truetype");
}

html,
body {
  margin: 0;
  font-family: "Michroma", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 2vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDrawer-paperAnchorDockedRight {
  border-left: 0px solid #6b0d48 !important;
}

/* width */

::-webkit-scrollbar {
  width: 20px;
}

iframe .widget-tracklist-container {
  display: none !important;
}

/* Track */

::-webkit-scrollbar-track {
  background-color: #190110;
  box-shadow: inset 0 0 5px grey;
  border-radius: 1px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #6b0d48;
  border-radius: 1px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #8f0c5f;
}

.mobile-chat-drawer {
  width: 100% !important;
}

.mobile-chat-drawer > .MuiDrawer-paper {
  width: 100% !important;
  height: 91vh;
  top: 9vh;
}

.fade-in {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
