html body,
#root > div > header > svg {
  cursor: pointer;
  /* cursor: url("../cursor.png"), auto !important; */
}

body {
  background-color: black;
}

a {
  cursor: inherit !important;
}

a :hover {
  cursor: pointer;

  /* cursor: url("../cursor.png"), auto !important; */
}

.linkItem {
  cursor: pointer;

  /* cursor: url("../cursor.png"), auto !important; */
}

#root > div > div.makeStyles-bottomBarContainer-26 > div {
  cursor: pointer;

  /* cursor: url("../cursor.png"), auto !important; */
}

@font-face {
  font-family: "Michroma";
  font-style: normal;
  font-weight: normal;
  src: local("Michroma"), url(./fonts/Michroma-Regular.ttf) format("truetype");
}

.pageWrapper {
  padding: 10%;
  height: 100%;
  width: 100%;
  background-color: #bac7be;
}

.marquee {
  border: 1px solid #fff;
  padding: 5px;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  max-width: 72vw;
}

.marqueeTablet {
  width: 50vw;
}

.marqueePhone {
  width: 50vw;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite;
}

.playPauseAppbarWrapperNone {
  display: none;
  width: 0vw;
  height: 0vh;
}

.playPauseAppbar {
  background-color: #e419a1;
  border-radius: 100%;
  width: 5vh;
  height: 5vh;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  display: flex;
  position: absolute;
  right: 2vw;
}

.playpauseAppbar {
  cursor: pointer;

  /* cursor: url("../cursor.png"), auto; */
}

/* Make it move */

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.popUpPanel {
  width: 100%;
  height: 0%;
  background-color: #fff;
  position: absolute;
  transition: height 1s, top 1s;
  top: 100%;
  overflow: auto;
}

.popUpPanelBig {
  height: 91vh;
  top: 9vh;
}

.panelTopArea {
  position: relative;
  text-align: right;
  padding-top: 1vh;
  padding-right: 1vw;
}

.panelArea {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panelRemover {
  cursor: pointer;

  /* cursor: url("../cursor.png"), auto; */
}

.scheduleContainer {
  padding-top: 3vh;
}

.scheduleIFrame {
  height: 75vh;
  width: 90vw;
}

.pad {
  margin-right: 10px;
}

.genreLink {
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
}

.genreLink:hover,
.genreLinkGenre:hover,
.archiveLink:hover,
.testGenre:hover {
  color: #e419a1;
}

.testGenre {
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in-out;
  font-size: 32px;
}
